import { render, staticRenderFns } from "./Chem1LC_Q4S1_HazardsTable.vue?vue&type=template&id=150d8c96&scoped=true&"
import script from "./Chem1LC_Q4S1_HazardsTable.vue?vue&type=script&lang=js&"
export * from "./Chem1LC_Q4S1_HazardsTable.vue?vue&type=script&lang=js&"
import style0 from "./Chem1LC_Q4S1_HazardsTable.vue?vue&type=style&index=0&id=150d8c96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "150d8c96",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCombobox,VContainer,VImg,VSelect})
